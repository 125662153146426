.App {
  text-align: center;
}

.middle {
  margin: 0 auto;
  width: 960px;
  display: block;
}

.full {
  width: 900px;
}
